export function getSystemName() {
  let system_name = localStorage.getItem('system_name');
  if (!system_name) return 'vPush';
  return system_name;
}
export function getServerAddress() {
  let server_address = localStorage.getItem('server_address');
  if (!server_address) return  window.location.origin;
  return server_address;
}
export function getLogo() {
  let logo = localStorage.getItem('logo');
  if (!logo) return '/logo.png';
  return logo;
}